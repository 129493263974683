
import React, { useState, useEffect } from "react";
// import "lightgallery.js/dist/css/lightgallery.css";
// import axios from "axios";
// import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import LightGallery from 'lightgallery/react';
// import { LightGallerySettings } from 'lightgallery/lg-settings';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import slide1 from '../../assets/images/school-img/slide1.jpg';
import slide2 from '../../assets/images/school-img/slide2.jpg';
import slide3 from '../../assets/images/school-img/slide3.jpg';
import students from '../../assets/images/school-img/students.jpeg';
import './Gallery.css';
// import "./styles.css";


function GalleryMain() {
 
  return (
    <>
     <div class="container my-4">
     
        <h2 style={{ textAlign: "center" }}>  Events & Gallery</h2>

        <div class="divider div-transparent con"></div>

        <section className="main_gallery">

        <LightGallery plugins={[lgZoom, lgVideo]} mode="lg-fade">
          
          <a
            className="gallery-item"
            data-src={slide1}
            
          >
            <img
            style={{ maxWidth: '360px' }}
              className="img-fluid"
              src={slide1}
            />
          </a>

          <a
            className="gallery-item"
            data-src={slide2}
            
          >
            <img
            style={{ maxWidth: '360px' }}
              className="img-fluid"
              src={slide2}
            />
          </a>

          <a
            className="gallery-item"
            data-src={slide3}
            
          >
            <img
            style={{ maxWidth: '360px' }}
              className="img-fluid"
              src={slide3}
            />
          </a>

          <a
            className="gallery-item"
            data-src={slide3}
            
          >
            <img
            style={{ maxWidth: '360px' }}
              className="img-fluid"
              src={students}
            />
          </a>
        </LightGallery>
        </section>
     
     </div>
    </>
  );
}

export default GalleryMain;