import React from 'react';
// import emaill from '../../../assets/images/all-icon/emaill.png';
// import map from '../../../assets/images/all-icon/map.png';
// import NewsTickers from '../NewsTickerSection/NewsTicker.js';
// import { NavLink } from 'react-router-dom';
const topBar = () => {
  return (
    <div className='top-bar pt-1 pb-1 text-light'>
     <div class="container-fluid px-lg-5">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="header-contact text-lg-left text-center">
                            <ul>
                                {/* <li><img src={map} alt="icon"/><span>
                                    <NavLink to="publicmandatorydisclouser" className="">
                                    Public Mandatory Disclouser
                                    </NavLink>
                                </span></li> */}
                                {/* <li><img className='topicon' src={emaill} alt="icon" /> :<span>schooladmin@unityschool.net</span></li> */}
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="header-opening-time text-lg-right text-center">
                            {/* <NewsTickers />
                             */}
                        </div>
                    </div>
                </div> 
            </div>
    </div>
  );
};

export default topBar;
