import React from 'react';

import Section from '../../../HOC/Section';
import slide2 from '../../../assets/images/school-img/slide2.jpg';
import GreatFacilities from '../../UI/GreatFacilities/GreatFacilities.js';
const QuickLinkSection = () => {
  return (
    <Section id='quicklinksection'>
      <div className='container-fluid pt-2 pb-5 px-lg-5'>
        
        <div className='section-content'>
          <div className='row'>
           
            <div className='col-md-12 col-lg-6'>
              {/* <h3 className='about-title'>facilities</h3> */}
              <div class="section-title">
                  <h2>Why Choose Us</h2>  
              </div>
              <div className='about-description'>
                <p class="text-justify">
                Unity International School is a wonderful place to study. My colleagues are smart, kind, and supportive. The school is well organized and efficient. But what keeps me coming back are the wonderful enviroment I get to understand and learn from every day. we are in the right hands.


                </p>
               <div id="about" class="py-2">
               <h5 class="mt-2"> Great Facilities</h5>
               
               </div>
                <div className='pos_rel'>
                  
                <GreatFacilities />
                
                </div>
                                   
                {/* <button className='btn btn-primary rounded-0 mt-2'>Read More  <i class="fas fa-arrow-right"></i> </button> */}
              </div>
            </div>

            <div className='col-md-12 col-lg-6 mb-3'>
              <div className='aboutImage bg-overlay fds'>
                <img src={slide2} alt='about company' />
              </div>
            </div>


          </div>
        </div>
      </div>
    </Section>
  );
};

export default QuickLinkSection;
