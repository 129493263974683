import React from 'react';
import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function ContactDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { previousSchoolName, previousSchoolAddress, previousSchoolClass, newClass, newClassSection } = formValues;
  return (
    <Grid item container direction="column" xs={12} sm={8}>
      <Typography variant="h6" gutterBottom className={classes.title}>
          Acedamics details
      </Typography>
      <Grid container>
        
        <React.Fragment>
          <Grid item xs={7}>
            <Typography gutterBottom>Previous School Name:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom>{previousSchoolName}</Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={7}>
            <Typography gutterBottom>Previous School Address:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom>{previousSchoolAddress}</Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={7}>
            <Typography gutterBottom>Previous Class:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom>
            <Typography gutterBottom>{previousSchoolClass}</Typography>
            </Typography>
          </Grid>
        </React.Fragment>

        <React.Fragment>
          <Grid item xs={7}>
            <Typography gutterBottom>Joining Class:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom>
            <Typography gutterBottom>{newClass}</Typography>
            </Typography>
          </Grid>
        </React.Fragment>

        <React.Fragment>
          <Grid item xs={7}>
            <Typography gutterBottom>Class Section:</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom>
            <Typography gutterBottom>{newClassSection}</Typography>
            </Typography>
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}

export default ContactDetails;
