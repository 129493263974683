import React from 'react'
import About from '../sections/About/About.js';
import edges from '../../assets/images/school-img/edges.jpg';
import TabsSection from '../sections/TabsSection/TabsSection.js';
function AboutUs() {
  return (
    <>
     <section id='facts' className='factss'>
      <div
        className='facts-container'
        style={{ backgroundImage: `url(${edges})` }}
      >
        <div class="container op2 aos-init aos-animate cta" data-aos="zoom-in">

<div class="row justify-content-center">
    <div class="col-md-8">
     <div class="text-center">
         <h3>About Us</h3>
         <div class="divider div-transparent"></div>
         <p class="text-white mt-3">
        Our Academics is best Solution for Education
         </p>
        
        
     </div>
    </div>
</div>

</div>
      </div>
    </section>
        <About />

        <TabsSection />
    </>
  )
}

export default AboutUs