import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, CheckboxField, SelectField } from '../../FormFields';

const newClassSections = [
  {
    value: null,
    label: 'None'
  },
  {
    value: 'Section A',
    label: 'Section A'
  },
  {
    value: 'Section B',
    label: 'Section B'
  },
  {
    value: 'Section C',
    label: 'Section C'
  }
];
export default function AcedemicForm(props) {
  const {
    formField: { 
      previousSchoolName,
      previousSchoolAddress,
      previousSchoolClass,
      newClass,
      newClassSection,
       }
  } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      Academic details
      </Typography>
      <Grid container spacing={3}>
      <Grid item xs={12}>
          <InputField name={previousSchoolName.name} label={previousSchoolName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={previousSchoolAddress.name} label={previousSchoolAddress.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={previousSchoolClass.name} label={previousSchoolClass.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={newClass.name} label={newClass.label} fullWidth />
        </Grid>
        
        
        <Grid item xs={12} sm={6}>
          <SelectField
            name={newClassSection.name}
            label={newClassSection.label}
            data={newClassSections}
            fullWidth
          />
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}