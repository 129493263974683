import React from 'react';
import MaterialLayout from './Layout/MaterialLayout';
import CheckoutPage from './CheckoutPage';

function LayoutAccess() {
  return (
    <div className="layout_access_admission">
      <MaterialLayout>
        <CheckoutPage />
      </MaterialLayout>
    </div>
  );
}

export default LayoutAccess;