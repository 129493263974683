import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    firstName,
    lastName,
    fathername,
    mothername,
    gender,
    address1,
    city,
    zipcode,
    country,
    useAddressForPaymentDetails,
    // nameOnCard,
    // cardNumber,
    // expiryDate,
    // cvv
  }
} = checkoutFormModel;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [fathername.name]: '',
  [mothername.name]: '',
  [gender.name]: '',
  [address1.name]: '',
  [city.name]: '',
  [zipcode.name]: '',
  [country.name]: '',
  [useAddressForPaymentDetails.name]: false,
  // [nameOnCard.name]: '',
  // [cardNumber.name]: '',
  // [expiryDate.name]: '',
  // [cvv.name]: ''
};
