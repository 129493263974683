import React from 'react';
import '../../App.css';
import Sections from '../sections/Sections';
export default function Home() {
  return (
    <>
      {/* <h1 className='home'>EPIC</h1> */}
    
        <Sections />
      
    </>
  );
}
