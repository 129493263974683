import React from 'react';
import './SocialStyle.css';
function SocialMedia() {
  return (
    <>
        <div className='social_menu'>
        
        <ul class="footer-social-media-container justify-content-between"> 
    <li class="footer-social-media-icon"><a href="#" class="footer-link-icon"><i class="fab fa-facebook"></i></a></li>
    <li class="footer-social-media-icon"><a href="#"  class="footer-link-icon"><i class="fab fa-twitter"></i></a></li>
    <li class="footer-social-media-icon"><a href="#" class="footer-link-icon"><i class="fab fa-youtube"></i></a></li>
    <li class="footer-social-media-icon"><a href="#" class="footer-link-icon"><i class="fab fa-whatsapp"></i></a></li>
    <li class="footer-social-media-icon"><a href="#" class="footer-link-icon"><i class="fab fa-instagram"></i></a></li>

</ul>
    
        </div>    
    </>
  )
}

export default SocialMedia