import React from 'react';
import './MisnoryGrid.css';
import { NavLink } from 'react-router-dom';
import download from '../../../assets/images/all-icon/download.png';
const MisnoryGrid = () => {
  return (
    <>
      <div class="container-fluid px-lg-5">
        <div class="misnory_box">
          <div class="box box1 bg-none">
            <div class="box-item">
            <div class="about-title text-left"><h1><span>Facilities </span><span class="pl-2"> </span></h1></div>
            <div class="section-title text-left"><h2>Media gallery</h2></div>
            <p class="text-left">We Have Lot’s Of Experience Teacher To Teach The Students and Provide Awesome Program To Build Bright Future</p>
            </div>
          </div>

          <div class="box box2">
            <div class="box-item">
              <div class="flip-box">
                <div class="flip-box-front text-center">
                  <div class="inner color-white">
                  {/* <h3 class="flip-box-header adj_height">Library</h3> */}
                  </div>
                </div>
                <div class="flip-box-back text-center">
                  <div class="inner color-white">
                    
                   <NavLink to="gallery" className="flip-box-button adj_height">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box box3">
            <div class="box-item">
              <div class="flip-box">
                <div class="flip-box-front text-center">
                  <div class="inner color-white">
                  {/* <h3 class="flip-box-header">Science Lab</h3> */}
                  </div>
                </div>
                <div class="flip-box-back text-center">
                  <div class="inner color-white">
                    
                   <NavLink to="gallery" className="flip-box-button">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box box4">
            <div class="box-item">
              <div class="flip-box">
                <div class="flip-box-front text-center">
                  <div class="inner color-white">
                  {/* <h3 class="flip-box-header adj_height">Classroom</h3> */}
                  </div>
                </div>
                <div class="flip-box-back text-center">
                  <div class="inner color-white">
                    
                   <NavLink to="gallery" className="flip-box-button adj_height">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box box5">
            <div class="box-item">
              <div class="flip-box">
                <div class="flip-box-front text-center">
                  <div class="inner color-white">
                  {/* <h3 class="flip-box-header">Classroom</h3> */}
                  </div>
                </div>
                <div class="flip-box-back text-center">
                  <div class="inner color-white">
                    
                   <NavLink to="gallery" className="flip-box-button">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box box6">
            <div class="box-item">
              <div class="flip-box">
                <div class="flip-box-front text-center">
                  <div class="inner color-white">
                  {/* <h3 class="flip-box-header">Classroom</h3> */}
                  </div>
                </div>
                <div class="flip-box-back text-center">
                  <div class="inner color-white">
                    
                   <NavLink to="gallery" className="flip-box-button">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box box7">
            <div class="box-item">
              <div class="flip-box">
                <div class="flip-box-front text-center">
                  <div class="inner color-white">
                  {/* <h3 class="flip-box-header">Our Campus</h3> */}
                  </div>
                </div>
                <div class="flip-box-back text-center">
                  <div class="inner color-white">
                    
                    <NavLink to="gallery" className="flip-box-button">
                      View More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MisnoryGrid;