import React from 'react';
import { Typography } from '@material-ui/core';

function CheckoutSuccess() {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom className='text-center'>
        Thank you.
      </Typography>
      <Typography variant="subtitle1" className='text-center'>
        Your Admission Form Submitted. We have emailed your admission confirmation.
      </Typography>
    </React.Fragment>
  );
}

export default CheckoutSuccess;
