import React from 'react';
import Layout from './components/layout/Layout';
import Header from './components/UI/Header/Header';
import MobileBottomBar from './components/sections/MobileBottomBar/MobileBottomBar';
import Footer from './components/UI/Footer/Footer';
import QuickEnquiry from './components/UI/QuickEnquiry/QuickEnquiry.js';
function App() {
  return (
    <>
      <Header />
      <QuickEnquiry />
      <Footer />
      <section class="call-buton">
        <a class="cc-calto-action-ripple" href="tel:9934015066">
          <i class="fas fa-phone-alt"></i>
          <span class="num"></span>
        </a>
      </section>
      <MobileBottomBar />
    </>
  );
}

export default App;
