
// "https://api.aisbiharsharif.co.in/gallerylist/"

import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'

class NewsBanner extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images:[],
      image:"",
      heading:"",
      detail:"",
    }
  }

  componentDidMount() {
    // #1. First of all you have to fetch the images.newsandupdate
    fetch('https://unity.broadsoftsolutions.com/gallerylist/')
      .then(response => response.json()) // If it's a JSON response, you have to parse it firstly
      .then(images => this.setState({ images })) // #2. After that you have to keep the images in the component's state.
  }

  render () {
    const { images } = this.state

    if (!images) return <div>Images are not fetched yet!</div>

    // #3. Finally, render the `<Carousel />` with the state's images.
    return (
     <>
       
        <Carousel
        infiniteLoop={true}
        showThumbs={false}
        showIndicators={false}
        interval="3000"
        autoPlay={true}
        stopOnHover={true}
        showStatus={false}
      >
        {images.map((imagesrc) => {
          return (
            <>
              <div>
                <img src={imagesrc.image} alt="" />
                <p className="legend">{imagesrc.heading}</p>
              </div>
              
            </>
          );
        })}
      </Carousel>
       
     </>
    );
  }
}
export default NewsBanner;

     