import React from 'react'
import { BrowserRouter, NavLink, Link } from 'react-router-dom';
function MobileBottomBar() {
  return (
    <>
   <div class="bottom_menu">
   <div class="container">
        <div class="row">
            <div class="col-2">
            <NavLink to="/" className="flip-box-button active">
                      <i class="fas fa-home"></i>
                    </NavLink>
            </div>
            <div class="col-4 lastbtn">
                
            <NavLink to="vjconcept" className="flip-box-button">
            <span class="triangle triangle-4"></span>
                      V.J Concept
                    </NavLink>
            </div>
            <div class="col lastbtn">
            
            <NavLink to="vjphysics" className="flip-box-button">
            <span class="triangle triangle-4"></span>
                      V.J Physics Centre
                    </NavLink>
            </div>
                 
        </div>
      </div>
   </div>
    </>
  )
}

export default MobileBottomBar