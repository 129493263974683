import React from 'react';

import Link from '../Link/Link';

const footer = () => {
  return (
    <footer className=''>
      <div className='container text-light pt-5'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-4 mb-5'>
            <div className='footer-title'>
              <h6>About Us</h6>
            </div>
            <div className='footer-content'>
              <p class='text-white text-justify'>
                <small >
                Unity International management is a unique team of experienced educationists, qualified and trained teachers and corporate managers, it strives to achieve the objective of the organization via continuous research, introduction of latest training methodologies and follow up to ensue adherence to set guidelines.        
                </small>
              </p>
              
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-2 mb-5'>
            <div className='footer-title'>
              <h6>Quick Links</h6>
            </div>
            <div className='footer-content'>
              <ul className='list-group quick-links'>
                <li>
                  <Link target='home' offset={-120}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link target='about'>About</Link>
                </li>
                <li>
                  <Link target='services'>Services</Link>
                </li>
                <li>
                  <Link target='blog'>Blog</Link>
                </li>
                <li>
                  <Link target='contact'>Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Latest News</h6>
            </div>
            <div className='footer-content'>
              <p>
                <small className='text-white'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </small>
              </p>
              <p>
                <small className='text-white'>
                  Pellentesque et pulvinar enim. Quisque at tempor ligula Natus
                  error sit voluptatem
                </small>
              </p>
              <p>
                <small className='text-white'>accusantium doloremque</small>
              </p>
            </div>
          </div>
          <div className='col-sm-6 col-md-6 col-lg-3 mb-5'>
            <div className='footer-title'>
              <h6>Contact Us</h6>
            </div>
            <div className='footer-content'>
              <p className='text-white'>
                <small>Address : Kanchanpur, Bihar Sharif, <br></br> Nalanda, Bihar-803216 </small>
              </p>
              <p className='text-white'>
                <small>Call Us : 09934015066, 09934010046</small>
              </p>
              <p className='text-white'>
                <small>Email : schooladmin@unityschool.net</small>
              </p>
              <div className='social-media mt-4'>
                
              <ul>
        <li><a href="#"><span></span></a></li>
        <li><a href="#"><span></span></a></li>
        <li><a href="#"><span></span></a></li>
        <li><a href="#"><span></span></a></li>
    </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bottom-footer pt-3 pb-3 text-center'>
      <div class="footer__copyright">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        
                        <div class="footer__copyright__text">
                            <p class="text-white">Copyright © <script>document.write(new Date().getFullYear());</script>2022 Unity International All rights reserved. </p>
                        </div>
                       
                    </div>
                    <div class="col-lg-6">
                        <ul>
                            <li>Designed & Developed By<a href="https://broadsoftsolutions.com/" target="_blank" class="com pl-2"> Broadsoft Solutions</a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
