import React from 'react';
import slide1 from '../../../assets/images/school-img/slide1.jpg';
import slide2 from '../../../assets/images/school-img/slide2.jpg';
import slide3 from '../../../assets/images/school-img/slide3.jpg';
function Home(props) {
  return (
    <div>
        <section className="hero-wrap">
        <div id="demo" class="carousel slide" data-ride="carousel">


<ul class="carousel-indicators">
  <li data-target="#demo" data-slide-to="0" class="active"></li>
  <li data-target="#demo" data-slide-to="1"></li>
  <li data-target="#demo" data-slide-to="2"></li>
</ul>


<div class="carousel-inner">
  <div class="carousel-item active">
    <img src={slide1} alt="slide 1"></img>
  </div>
  <div class="carousel-item">
  <img src={slide2} alt="slide 2"></img>
  </div>
  <div class="carousel-item">
  <img src={slide3} alt="sloide 3"></img>
  </div>
</div>


<a class="carousel-control-prev" href="#demo" data-slide="prev">
  <span class="carousel-control-prev-icon"></span>
</a>
<a class="carousel-control-next" href="#demo" data-slide="next">
  <span class="carousel-control-next-icon"></span>
</a>

</div>
          <div class="container">
            <div class="row slider-text js-fullheight align-items-center banner_text">
              <div class="col-md-10 col-lg-6 ftco-animate fadeInUp ftco-animated">
                <div class="text w-100">
                  <h2>Welcome to</h2>
                  <h1 class="mb-3">Unity International School</h1>
                  <p class="text-white mb-3">
                  We strive to provide highest quality education, bridging your child's success with well formed infrastructure of knowledge and resources.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  );
}

export default Home;