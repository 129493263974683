import React from 'react'
import Under from '../../assets/images/Under.jpg';
function VjConcept() {
  return (
    <>
    <section class="mt_50">
        <img src={Under} alt=""></img>
    </section>
    </>
  )
}

export default VjConcept