import React from 'react';
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from 'react-router-dom';
import Nav from '../Nav/Nav';
import TopBar from '../TopBar/TopBar';
import AfterTopBar from '../AfterTopBar/AfterTopBar.js';

import Home from '../../pages/Home';
import Admission from '../../pages/Admission.js';
import Gallery from '../../pages/Gallery';
import ContactUs from '../../pages/ContactUs';
import SignUp from '../../pages/SignUp';
import Facility from '../../pages/Facility';
import PublicMandatoryDisclouser from '../../pages/PublicMandatoryDisclouser.js';
import AboutUs from '../../pages/AboutUs';
import NewsAndEvents from '../../pages/NewsAndEvents.js';
import VjPhysics from '../../pages/VjPhysics.js';
import VjConcept from '../../pages/VjConcept.js';
const header = () => {
  return (
    <React.Fragment>
    <div className='header'>
      
      <TopBar />
      <AfterTopBar />
      {/* <Router> */}
      <Nav />
    </div>
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/admission' component={Admission} />
        <Route path='/newsandevents' component={NewsAndEvents} />
        <Route path='/gallery' component={Gallery} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/facility' component={Facility} />
        <Route path='/publicmandatorydisclouser' component={PublicMandatoryDisclouser} />
        <Route path='/aboutus' component={AboutUs} />
        <Route path='/vjphysics' component={VjPhysics} />
        <Route path='/vjconcept' component={VjConcept} />

      </Switch>
    {/* </Router> */}
      
    
    </React.Fragment>
  );
};

export default header;
