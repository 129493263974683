
// export default Admission;
import React from 'react';
import LayoutAccess from '../../components/Admission/LayoutAccess';

const Admission = () => {
return (
	<>
	<section className='admisssion_section'>
		{/* <img src={Building} alt="" className='opacity_building' />
		<img src={rain} alt="" className='opacity_building2' />
		<img src={rain} alt="" className='opacity_building3' /> */}
	<div class="container p-lg-5 my-3">
		<div className='row justify-content-center'>
			<div class="col-md-8">
			<LayoutAccess />
			</div>
		</div>
	</div>
	</section>
	</>
);
};

export default Admission;
