import React from 'react';
import unitylogo from '../../../assets/images/unitylogo.jpg';
import wave from '../../../assets/images/wave.png';
import suppor from '../../../assets/images/all-icon/suppor.png';
import ema from '../../../assets/images/all-icon/ema.png';
import SocialMedia from '../SocialMedia/SocialMedia.js';
function AfterTopBar() {
  return (
    <>
      <div class="header-logo-support pt-20 pb-20">
      {/* <img src={wave} alt="Logo" className='wave'/> */}
        <div class="container-fluid">
          <div class="row">
          <div class="col-lg-4 col-md-4">
              <div class="d-flex">
              <div class="logo">
               
               <a href="#">
                 <img src={unitylogo} alt="Logo" />
               </a>
             </div>
             <div className='only_mob'>
             <h5 class="pt-lg-3 text-center">U N I T Y</h5>
             <div class="divider div-transparent con"></div>
             <p class="text-center dsdas">
             International School

             </p>
             </div>
              </div>
            </div>


            <div class="col-lg-3 col-md-3">
              <div class="support-button float-right d-none d-md-block mt-lg-1">
                <div class="support float-left">
                  <div class="icon">
                    <img src={suppor} alt="icon" />
                  </div>

                  <div class="cont">
                    <p>Need Help ? contact us </p>
                    <span>9934015066, 9934010046</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-2 col-md-2">
              <div class="support-button float-left d-none d-md-block mt-lg-1">
                <div class="support float-left">
                  <div class="icon">
                    <img src={ema} alt="icon" />
                  </div>

                  <div class="cont">
                    <p>Need Help ? Email us </p>
                    <span>uisbihar@gmail.com</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-2 col-md-2">
              <div class="support-button d-none d-md-block">
                <div className='d-flex'>
                  <div class="button float-left">
                    <a href="#" class="main-btn top_btn">
                      Admission
                    </a>
                  </div>

                  <div class="button float-right ml-3">
                    <a href="#" class="main-btn top_btn">
                      Admin
                    </a>
                  </div>
                  <div>
                  {/* <SocialMedia /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AfterTopBar;
