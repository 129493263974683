import React from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@material-ui/core';

import StudentDetails from './StudentDetails';
import ContactDetails from './ContactDetails';
import AcedamicsDetails from './AcedamicsDetails';

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Details summary
      </Typography>
     
      <Grid container spacing={3}>
        <StudentDetails formValues={formValues} />
        <ContactDetails formValues={formValues} />
      </Grid>

      <Grid container spacing={3}>
        
        <AcedamicsDetails formValues={formValues} />
      </Grid>
    </React.Fragment>
  );
}
