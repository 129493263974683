import React from 'react';
import './QuickEnquiry.css';
const QuickEnquiry = () => {
  return (
   <>
   
<div class="container">
  <div class="row">
    <div class="enq-form">
	    <div class="formrotate">
        <p class="title">Quick Enquiry</p>
        <label class="user-icon">
          <i class="fa fa-envelope" aria-hidden="true"></i>
        </label> 
      </div>
	    <p class="message">
	    	Book Counsling Session With <br /> Our Experts 
	    </p>

		<form>
		    <div class="group">      
		      <input type="text" required="" />
		      <span class="highlight"></span>
		      <span class="bar"></span>
		      <label>Name</label>
		    </div>  
		    <div class="group">      
		      <input type="text" required="" />
		      <span class="highlight"></span>
		      <span class="bar"></span>
		      <label>Mobile No</label>
		    </div>
			<div class="group">      
		      <input type="text" required="" />
		      <span class="highlight"></span>
		      <span class="bar"></span>
		      <label>Enquiry For</label>
		    </div>
		    <button>
	      		<i class="fa fa-arrow-right"></i>
	      	</button>
	  	</form>
  	</div>
 </div>
</div>

   </>
  );
};

export default QuickEnquiry;
