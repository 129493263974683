import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import computer from '../../../assets/images/all-icon/computer.png';
import chemistry from '../../../assets/images/all-icon/chemistry.png';
import book from '../../../assets/images/all-icon/book.png';
import books from '../../../assets/images/all-icon/books.png';
import cctv from '../../../assets/images/all-icon/cctv.png';
import playground from '../../../assets/images/all-icon/playground.png';
export default class GreatFacilities extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
      }
      next() {
        this.slider.slickNext();
      }
      previous() {
        this.slider.slickPrev();
      }
  render() {
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true
    };
    return (
      <div>
         <div style={{ textAlign: "center" }}>
          <button className="btn silck_button" onClick={this.previous}>
          <i class="fas fa-long-arrow-alt-right"></i>
          </button>
          <button className="btn silck_button" onClick={this.next}>
          <i class="fas fa-long-arrow-alt-left"></i>
          </button>
        </div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div className="mono_icon">
            <img src={computer} alt="" />
            <h6>Comuter Lab</h6>
          </div>
         
          <div className="mono_icon">
            <img src={chemistry} alt="" />
            <h6>Science Lab</h6>
          </div>
          <div className="mono_icon">
            <img src={book} alt="" />
            <h6>Books Library</h6>
          </div>
          <div className="mono_icon">
            <img src={cctv} alt="" />
            <h6>CCTV Security</h6>
          </div>
          <div className="mono_icon">
            <img src={books} alt="" />
            <h6>Digital Learning</h6>
          </div>
          <div className="mono_icon">
            <img src={playground} alt="" />
            <h6>Huge Playground</h6>
          </div>
        </Slider>

      </div>
    );
  }
}