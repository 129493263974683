import React from 'react';

import Section from '../../../HOC/Section';
import unitybuilding from '../../../assets/images/school-img/unitybuilding.jpg';
import hexa from '../../../assets/images/hexa.png';
const about = () => {
  return (
    <Section id='about'>
      <div className='container-fluid pt-2 pb-5 px-lg-5'>
        {/* <div className='section-header pt-5 pb-5 text-center'>
          <h3 className='section-title'>
            <span>Our </span>Company
          </h3>
          <h6 className='section-subtitle mr-auto ml-auto'>
            Individualized quality care that meets the total needs of the
            patient Individualized quality care that quality care that
            Individualized quality care that meets the total.
          </h6>
        </div> */}
        <div className='section-content fgfg'>
        <img class="img-fluid bg_pattern" src={hexa} alt='about us' />
          <div className='row'>
            <div className='col-md-12 col-lg-4 mb-3'>
              <div className='aboutImage khbbsdn bg-overlay mt-lg-2'>
                <img class="img-fluid" src={unitybuilding} alt='about us' />
              </div>
            </div>
            <div className='col-md-12 col-lg-8 mt-lg-5'>
            <div className='about-title'>
            <h1><span>About </span><span class="pl-2"> us</span></h1>
            </div>
              
              <div class="section-title">
                  <h2>Who We Are</h2>  
              </div>
              <div className='about-description'>
                <p class="text-justify">
                Unity International School is situated near the kanchanpur Bihar Sharif. The sylvan and pristine surroundings lend a serene touch to the impressive campus spread over several acres. A marvel of modern architecture, the campus contains well-lit airy classrooms, outdoor theatre, performing art block, cafeteria, spacious courtyards for indoor games, and beautifully landscaped lawns.
                     {/* <span class="highlight-yellow">(eg. Olympiad , NTSE, IIT- JEE, AIEEE (Now known as (JEE mains + Adv),AIPMT
                      (Now knows as NEET), AIIMS etc)</span>                                                                           */}
                </p>
                <p class="text-justify">
                Unity International School is a wonderful place to study. My colleagues are smart, kind, and supportive. The school is well organized and efficient. But what keeps me coming back are the wonderful enviroment I get to understand and learn from every day. we are in the right hands.
                </p>
                
                {/* <h5 class="mt-3"> We Provide -</h5>
                <ul>
                    <li>Learning program with after-school</li>
                     <li>Opportunities to scientific experiments</li>
                     <li>Positive learning environment</li>
                    <li>Learning through play</li>
                   
                </ul> */}
                                   
                {/* <button className='btn btn-primary rounded-0 mt-2'>Read More  <i class="fas fa-arrow-right"></i> </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default about;
