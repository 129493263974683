import React, { useEffect, useState } from "react";

import $ from 'jquery';
import './PublicMandatory.css';
import CALENDER from "../../assets/mpd/CALENDER.pdf";
// import RESULT from "../../assets/mpd/RESULT.pdf";
// import APPENDIX9 from "../../assets/mpd/APPENDIX9.pdf";
import FIRESAFETY from "../../assets/mpd/FIRESAFETY.pdf";
import BUILDINGSAFETY from "../../assets/mpd/BUILDINGSAFETY.pdf";
import WATER from "../../assets/mpd/WATER.pdf";
import NOC from "../../assets/mpd/NOC.pdf";
import PTA from "../../assets/mpd/PTA.pdf";
import FEESTRUCTURE from "../../assets/mpd/FEESTRUCTURE.pdf";
import SELFDECLARATION from "../../assets/mpd/SELFDECLARATION.pdf";
import SMC from "../../assets/mpd/SMC.pdf";
import RTE from "../../assets/mpd/RTE.pdf";
import TRUSTDEED from "../../assets/mpd/TRUSTDEED.pdf";
// import AFFLIATION from "../../assets/mpd/AFFLIATION.pdf";

export const useScrollHandler = () => {
  const [scroll, setScroll] = useState(1);

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 100;
      setScroll(scrollCheck);
    };

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [scroll, setScroll]);

  return scroll;
};
export default function PublicMandatoryDisclouser() {
  const scroll = useScrollHandler();

  return (
    <>
      {/* <section className='side_menu'>
      <input id="menu-switch" type="checkbox" />
    <nav id="menu" role="navigation" style={{ top: scroll ? "40px" : "150px" }}>
        <div class="brand">
          <h6>
          Mandatory Public Disclosure
          </h6>
          <div class="divider div-transparent con"></div>
          </div>            
        <ul>
            <li><a href="#a">GENERAL INFORMATION</a></li>
            <li><a href="#b">DOCUMENTS AND INFORMATION</a></li>
            <li><a href="#c">RESULTS AND ACADEMICS</a></li>
            <li><a href="#d"> STAFF AND TEACHING</a></li>
            <li><a href="#e">SCHOOL INFRASTRUCTURE</a></li>
        </ul>
    </nav>
    <div class="page-wrap">
        <label for="menu-switch" id="menu-toggle"></label>
           <div class="container" role="main">


            <div id="a">
                <h2 className="line-after">
                  <span>GENERAL INFORMATION</span>
                </h2>
                

				
            </div>


      </div>
   </div>
      </section> */}


<div class="container">
        <div class="row">
        <div class="col-md-12 setinmob">
            <section class="showcase set_new_height">
              {/* <img
                src={ff}
                alt="Picture"
              /> */}
              <div class="">
                <h2>
                  {" "}
                  <i class="fa fa-link"></i> Mandatory Public Disclosure
                </h2>
                <hr></hr>
               <div className="">
	  <ul class="quick_link unity">
                  {/* <li>
                    <a href={AFFLIATION} target="_blank">AFFILIATION</a>
                  </li> */}

                  <li>
                    <a href={CALENDER} target="_blank">CALENDER</a>
                  </li>
                  {/* <li>
                    <a href={RESULT} target="_blank">RESULT</a>
                  </li> */}
                  {/* <li>
                    <a href={APPENDIX9} target="_blank">APPENDIX 9</a>
                  </li> */}
                  <li>
                    <a href={FIRESAFETY} target="_blank">FIRE SAFETY</a>
                  </li>
                  <li>
                    <a href={BUILDINGSAFETY} target="_blank">BUILDING SAFETY</a>
                  </li>
                  <li>
                    <a href={WATER} target="_blank">WATER</a>
                  </li>
                  <li>
                    <a href={NOC} target="_blank">NOC</a>
                  </li>
                  <li>
                    <a href={PTA} target="_blank">PTA</a>
                  </li>

                  <li>
                    <a href={FEESTRUCTURE} target="_blank">FEE STRUCTURE</a>
                  </li>

                  <li>
                    <a href={SELFDECLARATION} target="_blank">SELF DECLARATION</a>
                  </li>
                  <li>
                    <a href={SMC} target="_blank">SMC</a>
                  </li>
                  <li>
                    <a href={RTE} target="_blank">RTE</a>
                  </li>

                  <li>
                    <a href={TRUSTDEED} target="_blank">TRUST DEED</a>
                  </li>
                </ul>
				</div>
              </div>
            </section>
          </div>
         
        </div>
      </div>
    </>
  )
}

// export default PublicMandatoryDisclouser