import React, { Fragment } from 'react';

import Home from './Home/Home';
import About from './About/About';
// import Service from './Service/Service';
import Facts from './Facts/Facts';
// import Blog from './Blog/Blog';
// import TabsSection from './TabsSection/TabsSection.js';
// import MapMarker from './MapMarker/MapMarker';
import QuickLinkSection from './QuickLinkSection/QuickLinkSection';
import DirectorSection from './DirectorSection/DirectorSection.js';
import MisnoryGrid from './MisnoryGrid/MisnoryGrid.js';
import Counter from './Counter/Counter.js';
import EnrollBanner from './EnrollBanner/EnrollBanner.js'
// import FoldingCard from './FoldingCard/FoldingCard.js';
const sections = () => {
  return (
    <Fragment>
      <Home />
      <EnrollBanner/>
      <About />
      <QuickLinkSection />
      <DirectorSection />
      {/* <FoldingCard /> */}
      {/* <Service /> */}
      <MisnoryGrid />
      <Counter />
      {/* <Blog /> */}
      {/* <TabsSection /> */}
      <Facts />
      {/* <MapMarker /> */}
    </Fragment>
  );
};

export default sections;
