import React from "react";
import NewsBanner from '../UI/NewsBanner/NewsBanner.js';
import img3 from '../../assets/images/img3.png'
import UpcomingEvents from '../../components/UpcomingNews/UpcomingEvents.js';
const NewsAndEvents = () => {
  return (
    <>
      <div class="container-fluid px-lg-5">
        <div class="row">
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-6">
                <div class="news_sec">
                  <div class="headings">
                    <h2 class="title">News & Updates</h2>
                    <span class="hr"></span>
                  </div>
                  <NewsBanner />
                  <h6 class="py-2 text-success">
                    JUNE 6, 2022 | STUDENT, EVENTS
                  </h6>

                  <h5>Event Heading</h5>
                  <p>
                  Creativity is the ability to generate and communicate original ideas and appreciate the nature of beauty. It fosters imagination, innovation, and a sense of aesthetics.
<br/>
Parents nurture the evolution of children’s ideas at home and teachers inspire creativity in the classroom when they encourage young people to express themselves through unstructured play, writing, poetry, magic, acting, photography, art, digital media, etc. When adults notice and praise youth for thinking outside the box and taking risks, the imaginations of young people blossom.
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="headings">
                  <h2 class="title">Upcoming Events</h2>
                  <span class="hr_short"></span>
                </div>
                <UpcomingEvents />
              </div>
            </div>
          </div>
          <div class="col-md-3 setinmob">
            <section class="showcase">
              <img
                src={img3}
                alt="Picture"
              />
              <div class="overlay">
                <h2>
                  {" "}
                  <i class="fa fa-link"></i> Quick Links
                </h2>
                <hr></hr>
                <ul class="quick_link">
                  <li>Alumni & Donors</li>

                  <li>Athletic Calendar</li>

                  <li>Exam's Calendar</li>

                  <li>Academic Programs</li>

                  <li>Tution And Fees</li>
                  <li>Results</li>
                  <li>Faculty Members</li>
                  <li>Academic Syllabus</li>
                  <li>Download Section</li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsAndEvents;
