import React from 'react';
// import CountUp from 'react-countup';
import './Counter.css';
import download from '../../../assets/images/all-icon/download.jpg';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp, { useCountUp } from 'react-countup';
const Counter = () => {
   
  return (
    <>
      <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
        {({ isVisible }) => (
          <div>
            <div class="section-block-grey counter_main">
              <div class="container-fluid px-lg-5">
               <div class="row">
                  
                  <div class="col-md-12">
                  <div class="section-heading center-holder">
                  <h3 className='oering text-center'>Offering access to high-quality Education for everyone.</h3>
                  <div class="section-heading-line"></div>
                  <p class="text-center my-3">
                  The leading global marketplace for learning and instruction. By connecting students all over the world to the best instructors, 
                    <br />
                    we helping individuals reach their goals and pursue their dreams.
                  </p>
                </div>
                    <div className='row justify-content-center'>
                      <div className='col-md-8'>
                      <div class="row mt-60">
                  <div class="col-md-6 col-sm-12 col-12">
                    <div class="serv-section-2">
                      <div class="serv-section-2-icon">
                        {" "}
                        <i class="fas fa-user-graduate"></i>{" "}
                      </div>
                      <div class="serv-section-desc">
                        {isVisible ? (
                          <CountUp
                            className="numm"
                            end={1000}
                            duration="5"
                            suffix="+"
                          />
                        ) : null}
                        <h4>Students</h4>
                        {/* <h5>Business Planning</h5>{" "} */}
                      </div>
                      <div class="section-heading-line-left"></div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-12">
                    <div class="serv-section-2 serv-section-2-act">
                      <div class="serv-section-2-icon serv-section-2-icon-act">
                        {" "}
                        <i class="fas fa-chalkboard-teacher"></i>{" "}
                      </div>
                      <div class="serv-section-desc">
                        {isVisible ? (
                          <CountUp
                            className="numm"
                            end={50}
                            duration="5"
                            suffix="+"
                          />
                        ) : null}
                        <h4>Faculty members</h4>
                        {/* <h5>Retirement Planning</h5>{" "} */}
                      </div>
                      <div class="section-heading-line-left"></div>
                    </div>
                  </div>
                </div>
                <div class="row mt-60 sfsdg mb-5">
                  <div class="col-md-6 col-sm-12 col-12">
                    <div class="serv-section-2">
                      <div class="serv-section-2-icon">
                        {" "}
                        <i class="fas fa-users"></i>{" "}
                      </div>
                      <div class="serv-section-desc">
                        {isVisible ? (
                          <CountUp
                            className="numm"
                            end={10}
                            duration="2"
                            prefix="1:"
                          />
                        ) : null}
                        <h4>Students & Teacher ratio</h4>
                        {/* <h5>Secure Business</h5>{" "} */}
                      </div>
                      <div class="section-heading-line-left"></div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-12">
                    <div class="serv-section-2 serv-section-2-act">
                      <div class="serv-section-2-icon serv-section-2-icon-act">
                        {" "}
                        <i class="far fa-clock"></i>{" "}
                      </div>
                      <div class="serv-section-desc">
                        {isVisible ? (
                          <CountUp
                            className="numm"
                            end={24}
                            duration="2"
                            suffix="/7"
                          />
                        ) : null}
                        <h4>24/7 security </h4>
                        {/* <h5>All time available </h5>{" "} */}
                      </div>
                      <div class="section-heading-line-left"></div>
                    </div>
                  </div>
                </div>
                      </div>

                      
                    </div>
                  </div>
               </div>
              </div>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </>
  );
};
export default Counter;