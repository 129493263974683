import React from 'react'
import cons from '../../assets/images/cons.jpg';
function VjPhysics() {
  return (
    <>
    <section class="mt-5">
        <img src={cons} alt=""></img>
    </section>
    </>
  )
}

export default VjPhysics