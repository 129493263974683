import React from 'react';
import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function ContactDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { address1, city, zipcode, state, country } = formValues;
  return (
    <Grid item container direction="column" xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
          Contact details
      </Typography>
      <Grid container>
        
        <React.Fragment>
          <Grid item xs={4}>
            <Typography gutterBottom>Address:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>{address1}</Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={4}>
            <Typography gutterBottom>City:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>{city}</Typography>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={4}>
            <Typography gutterBottom>Zip Code:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>
            <Typography gutterBottom>{zipcode}</Typography>
            </Typography>
          </Grid>
        </React.Fragment>

        <React.Fragment>
          <Grid item xs={4}>
            <Typography gutterBottom>State:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>
            <Typography gutterBottom>{state}</Typography>
            </Typography>
          </Grid>
        </React.Fragment>

        <React.Fragment>
          <Grid item xs={4}>
            <Typography gutterBottom>Country:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>
            <Typography gutterBottom>{country}</Typography>
            </Typography>
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}

export default ContactDetails;
