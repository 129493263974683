
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import directors from '../../../assets/images/school-img/Director/directors.jpg';
import Section from '../../../HOC/Section';
import './Director.css';
import Truncate from 'react-truncate';
class DirectorSection extends Component{
  constructor(...args) {
    super(...args);

    this.state = {
        expanded: false,
        truncated: false
    };

    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleLines = this.toggleLines.bind(this);
}

handleTruncate(truncated) {
    if (this.state.truncated !== truncated) {
        this.setState({
            truncated
        });
    }
}

toggleLines(event) {
    event.preventDefault();

    this.setState({
        expanded: !this.state.expanded
    });
}
  render() {
    const {
        more,
        less,
        lines 
    } = this.props;

    const {
        expanded,
        truncated 
    } = this.state;

  return (
    <Section id="director">
      <div className="container-fluid px-lg-5">
        <div class="row">
        <div className='col-md-4'>
              <div className='aboutImage bg-overlay setformobdir'>
                <img src={directors} alt='about us' />
              </div>
            </div>
          <div class="col-md-8">
          <div className='about-title'>
            <h1><span class="text-white">Director's </span><span class="pl-2 text-white"> Corner</span></h1>
            </div>
              
              <div class="section-title">
                  <h2 class="text-white">Director's Message</h2>  
              </div>

            <div>

            <p class="text-justify py-3">
            {/* <Truncate
                    lines={!expanded && lines}
                    ellipsis={(
                        <p>... <a href='#' onClick={this.toggleLines}>{more}</a></p>
                    )}
                    onTruncate={this.handleTruncate}
                > */}
                 
                 It is with profound pleasure that I welcome you to the Unity International School, Kanchanpur, Bihar Sharif, Nalanda. Our commitment at Unity International School, Nalanda is to provide a safe and intellectually challenging environment that will empower students to become innovative thinkers, creative, problem-solvers, compassionate citizen and lifelong learners. Under the aegis of this enable resilient young minds to bring home laurels.    
		We believe that every student has a gift to share and our dedicated teachers tirelessly endeavor to unlock their highest potential through a curriculum designed to provide stimulating and value-based learning. Our state – of – the art facilities motivate students to love critical thinkers and responsible decision – makers who wonder, dream big and relentlessly pose questions. As educators it is our responsibility to nurture relentlessly pose Questions. As education it is our responsibility to nurture and develop every learner to prepare them for life. Our students – the unitians live and learn by one care values. Perseverance, Empathy and Excellence. 
			We have learnt and wish to remain faithful champions of ‘Beti Bachao Beti Padho’, that envisage a progressive India empowering every girl child. ‘The only source of knowledge is experience’, remarked Albert Einstein, and to give India’s 

{/*               
                As India grows and develops, it will need new leaders in every
                field. Unity International Publics School has long history of murturing
                leaders, and you can find a dipsite in every major organization
                in pivotal decision making positions. The power of Unity International
                Public School will be your child's future wealth Children,like
                uncut diamond need to be polished before theirreal value
                emerges. At Unity International we have perfected the art of
                doing value emerges. At Unity International we have
                perfected the art of doing that I look forward to welcome your
                child into Unity International family. */}
              

                  
                    
                {/* </Truncate>
                
                {!truncated && expanded && (
                    <p> <a href='#' onClick={this.toggleLines}>{less}</a></p>
                )} */}
            
            </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
}
DirectorSection.defaultProps = {
    lines: 8,
    more: 'Read more',
    less: 'Show less'
};
 
DirectorSection.propTypes = {
    children: PropTypes.node.isRequired,
    lines: PropTypes.number,
    less: PropTypes.string,
    more: PropTypes.string
};
export default DirectorSection;
