import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "./UpcomingNews.css";
function UpcomingEvents() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 2500,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function(currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function(currentSlide) {
      console.log("after change", currentSlide);
    }
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("https://unity.broadsoftsolutions.com/UpcomingEventsList/");

      setData(result.data);
      console.log(typeof result.data);
      console.log(result.data);
    };

    fetchData();
  }, []);
  return (
    <>
    <div class="main_upcoming_news">
     <Slider {...settings}>
     {data.map((item) => (
       <>
       
        <ul class="upnews">
            
       {item.status===1? <li key={item.id}>
       <span className="date_tag">{item.eventdate}</span>
          <a>{item.header}</a>
          <p>{item.description}</p>
          
        </li>:null}
        </ul>
       </>
         
      
       
      ))}
     </Slider>
     </div>
    </>
  )
}

export default UpcomingEvents