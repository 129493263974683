
// import React from 'react'
// import './TabSection.css';
// function TabsSection() {
//   document.addEventListener("DOMContentLoaded", function() {
//     var tabs = document.querySelectorAll('.tabbed li');
//     var switchers = document.querySelectorAll('.switcher-box a');
//     var skinable = document.getElementById('skinable');
  
//     for (var i = 0, len = tabs.length; i < len; i++) {
//       tabs[i].addEventListener("click", function() {
//         if (this.classList.contains('active'))
//           return;
  
//         var parent = this.parentNode,
//             innerTabs = parent.querySelectorAll('li');
  
//         for (var index = 0, iLen = innerTabs.length; index < iLen; index++) {
//           innerTabs[index].classList.remove('active');
//         }
  
//         this.classList.add('active');
//       });
//     }
  
//     for (var i = 0, len = switchers.length; i < len; i++) {
//       switchers[i].addEventListener("click", function() {
//         if (this.classList.contains('active'))
//           return;
  
//         var parent = this.parentNode,
//             innerSwitchers = parent.querySelectorAll('a'),
//             skinName = this.getAttribute('skin');
  
//         for (var index = 0, iLen = innerSwitchers.length; index < iLen; index++) {
//           innerSwitchers[index].classList.remove('active');
//         }
  
//         this.classList.add('active');
//         skinable.className = 'tabbed round ' + skinName;
//       });
//     }
//   });
//   return (
//     <>
      

// <div class='tabbed round'>
//   <ul class="nav nav-tabs">
//     <li class="active"><a data-toggle="tab" href="#frist">1</a></li>
//     <li><a data-toggle="tab" href="#second">2</a></li>
//     <li><a data-toggle="tab" href="#third">3</a></li>
//   </ul>
// </div>
// <div class="tab-content">
//   <div id="frist" class="tab-pane active">
//     <h3>1</h3>
//     <p>Some content.</p>
//   </div>
//   <div id="second" class="tab-pane">
//     <h3>Menu 2</h3>
//     <p>Some content in menu 1.</p>
//   </div>
//   <div id="third" class="tab-pane">
//     <h3>Menu 3</h3>
//     <p>Some content in menu 2.</p>
//   </div>
// </div>

//     </>
//   )
// }

// export default TabsSection
import React from "react";
import ReactDOM from "react-dom";
import './TabSection.css';
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import bg from "../../../assets/images/bg.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

export default function TabsSection() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
   <>
    <div class="container-fluid px-4 tabimg">
   
   <div class="row">
    <div class="col-md-6 px-lg-3" id="about">
    <div class="about-title"><h1><span>Core </span><span class="pl-2"> Value</span></h1></div>
    <div class="section-title mb-lg-5"><h2>What sets us apart</h2></div>
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="OUR VISION" {...a11yProps(0)} />
          <Tab label="OUR MISSION " {...a11yProps(1)} />
          <Tab label="OUR MOTTO" {...a11yProps(2)} />
          {/* <Tab label="Item Four" {...a11yProps(3)} />
          <Tab label="Item Five" {...a11yProps(4)} />
          <Tab label="Item Six" {...a11yProps(5)} />
          <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
     
      <h4 className="seth4">
    We Belief that a balanced education leads to a powerful life
   </h4>
     <p class="mt-lg-3">
      <b> To make the UNITY INTERNATIONAL SCHOOL a leading, learning community of motivated staff and students engaged in realizing and exploring the limits of their full and human potential to graciously contribute to the society.</b>
     </p>
                                                                  
      </TabPanel>
      <TabPanel value={value} index={1}>
   
     
     <p class="mt-lg-3 text-justify">
       To provide an educational environment in which students feel safe, secured,empoered, energized and unlimited in pursuing learning experiences to their maximum potential. UNITY INTERNATIONAL SCHOOL provides holistic education that emphasizes high academic and social standards, promotes healthly lifestyle, cultivates critical thinking, fosters scientific temper, instills desires for life long learning.
     </p>
    
      </TabPanel>
      <TabPanel value={value} index={2}>
      
      <p class="text-justify">
      Our motto is to provide high quality
 education to our children at a most
 economical and affordable cost. 
Unity International provides homely
 atmosphere with comfortable size of
 classroom and sitting arrangement inside the
 class, we want the children to feel "AT HOME"
 inside the school campus and learn in an 
environment of love, care and individual attention,
 in order to enable them to face the outside would
 with confidence as they grow.   
      </p>                                                                     


      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </div>
    </div>
    <div class="col-md-6">
      <div class="">
        <img src={bg} alt="" />

        
      </div>
    </div>
   </div>
    </div>
   </>
  );
}

