import React from 'react';

import Section from '../../../HOC/Section';
import slide2 from '../../../assets/images/school-img/slide2.jpg';
import './Facts.css';
const Facts = () => {
  return (
    <Section id='facts'>
      <div
        className='facts-container'
        style={{ backgroundImage: `url(${slide2})` }}
      >
        <div class="container op2 aos-init aos-animate cta" data-aos="zoom-in">

<div class="row justify-content-center">
    <div class="col-md-8">
     <div class="text-center">
         <h3>Admissions open now !</h3>
         <p class="text-white mt-3">

         Our lessons integrate listening, doing, thinking and speaking to deepen the learning for every student. Our teachers bring our curriculum to life, connecting numerous academic disciplines.
         </p>
         <a class="cta-btn" href="tel:+919934015066">  <i class="fas fa-phone-alt"></i> 09934015066, 09934010046
         </a>

         <p class="text-white mt-3">

         Get in touch with us to discuss it
           </p>
     </div>
    </div>
</div>

</div>
      </div>
    </Section>
  );
};

export default Facts;
