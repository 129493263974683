import React, { useState, useEffect } from 'react';
import SocialMedia from '../../UI/SocialMedia/SocialMedia.js';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';

function Nav() {
  const [navClass, setNavClass] = useState('');
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      let navClass = '';
      if (window.scrollY >= 200) {
        navClass = 'scrolled';
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <>
      <nav className={`navbar navbar-expand-md ${navClass}`}>
        {/* <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          
          <i class='fab fa-firstdraft' />
        </Link> */}
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa_times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"} id="nav_ul">
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/aboutus" className="nav-links" onClick={closeMobileMenu}>
              About Us
            </Link>
          </li>

          <li>
            <Link
              to="/facility"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Facilities
            </Link>
          </li>
          <li>
            <Link
              to="/admission"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Admission
            </Link>
          </li>

          <li className="dropdown">
            <Link to="#" className="nav-links trigger-drop">
              Academic <i className="fas fa-caret-down" />
            </Link>
            {/* {dropdown && <Dropdown />} */}

            <ul class="drop">
              <li className='d-sm-none'>
                <Link
                  to="/facility"
                  onClick={closeMobileMenu}
                >
                  Facilities
                </Link>
              </li>

              <li>
                <Link
                  to="/facility"
                  onClick={closeMobileMenu}
                >
                  Teachers
                </Link>
              </li>

              <li>
                <Link
                  to="/facility"
                  onClick={closeMobileMenu}
                >
                  Results
                </Link>
              </li>

              <li className='d-sm-none'>
                <Link
                  to="/admission"
                  onClick={closeMobileMenu}
                >
                  Admission
                </Link>
              </li>

              <li className='d-sm-none'>
                <Link
                  to="/publicmandatorydisclouser"
                  onClick={closeMobileMenu}
                >
                  Public Mandatory Disclouser
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <Link
              to="/newsandevents"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              News & Events
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/gallery" className="nav-links" onClick={closeMobileMenu}>
              Gallery
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact-us"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li>
          <li>
            <Link
              to="/publicmandatorydisclouser"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Public Mandatory Disclouser
            </Link>
          </li>

          <SocialMedia />
        </ul>
       
      </nav>
    </>
  );
}

export default Nav;