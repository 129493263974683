import React from 'react'
import './EnrollBanner.css';
import { NavLink } from 'react-router-dom';
import st from '../../../assets/images/school-img/st.png';
import bluegreen from '../../../assets/images/bluegreen.png';
function EnrollBanner() {
  return (
    <>
      <div class="banner banner--gradient-bg">
      <img className='wave' src={bluegreen} alt="" />
      {/* <img src={st} alt="" /> */}
       <div class="container-fluid px-lg-3">
       <div class="row">
         
         <div class="col-md-7">
           <div class="enroll_content py-5">
             {/* <div>
             <div className='about-title'>
            <h1><span>Apply for    </span><span class="pl-2"> Admission</span></h1>
            </div>
              
              <div class="section-title">
                  <p class="text-justify">We are currently accepting applications <br /> for Grades 4 through 10 for
the 2022—23 academic year.</p>  
              </div>
              <NavLink to="admission" className="top_btn main-btn">
                      Enroll Now
              </NavLink>
             </div> */}

            
           </div>
         </div>

         
       </div>
       </div>
      </div>
    </>
  );
}

export default EnrollBanner