import React from 'react';
import _DSC6235 from '../../assets/images/school-img/_DSC6235.JPG';
import classroom from '../../assets/images/classroom.jpg';
import ventilation from '../../assets/images/ventilation.jpeg';
import playground from '../../assets/images/playground.jpg';
import aquestic from '../../assets/images/aquestic.jpg';
import light from '../../assets/images/light.jpg';
import laboratory from '../../assets/images/laboratory.jpg';
import toilet from '../../assets/images/toilet.jpg';
import fac from '../../assets/images/fac.png';
import side from '../../assets/images/side.png';
export default function Facility() {
  return (
    <>
      
      	<section className="facility_section">
		
          <div class="container">
				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss">
						<div class="solar_about_detail">
							<h5 class="solar_center solar_left">We are providing</h5>
							<h1 class="text_span">Great Facilities</h1>
							<p>
								
							Unity International is an English medium  residential school intended for both boys 
							and girls aged 3 and above. This school situated conviently at Kanchanpur, Bihar Sharif. It is easily accessible by road and rail. Spacious class rooms and
							  comfortable residential
							 facilities are provided. Transportation is provided by the school for the day scholars. 
								 
								 
								 </p>
								 <h6>
							<b>We provide following amenities:-</b>
					 
</h6>
<ul>
  <li>Striking school buildings unified with lush green gardens and lawns</li>
  <li>Huge, airy classrooms with cross aeration and natural light overlooking gardens</li>
  <li>Indoor and outdoor playing Courts and sports area</li>
  <li>State-of-the-art Science Lab, Mathematics and Languages Laboratory</li>
  <li>Amenities for indoor physical activities like table tennis, chess and board games</li>
  
</ul>
							{/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight" data-wow-delay="0.3s">
						<div class="about_rotate">
						<img src={fac} alt="about-us" />

						
						</div>						
					</div>										
				</div>




				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss order-lg-2">
						<div class="solar_about_detail">
							
<ul>
  
  <li>
  Day Care facility on request
  </li>

  <li>
  A big auditorium with first-rate acoustics and a capacity to seat at least 1000 people
	</li>


	<li>
	Well-equipped laboratories for Chemistry, Physics, Biology and Computer Science
	</li>


	<li>
	Meditation centre and Yoga centre
	</li>


	<li>
	Large rooms for Art, Craft, Dance and Music
	</li>

	<li>
	Huge and hygienic dining hall
	</li>

	<li>
	School bus services to transport children to and from school
	</li>

	and many more...
</ul>
							{/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight order-lg-1" data-wow-delay="0.3s">
						<div class="about_rotate">
						
						<img src={side} alt="about-us" />
						</div>						
					</div>										
				</div>



				<hr />


				<div className="facilities_blocks">
				<div class="row  sdsdsds">
					<div class="col-lg-12 col-md-12 setcss">
						<div class="solar_about_detail">
							<h5 class="solar_center solar_left">We are providing</h5>
							<h1 class="text_span">Planning to enroll your child in a good school? Consider these factors for wise decision!</h1>
							<p>
								
							In India, basic facilities provided in a school depend on its standards and furthermore on the fee structure. There are various perks of studying in international schools which are in the higher end of the range as they can give a great deal instead of simple and fundamental amenities, while there are a few institutes and government-supported schools that are attempting to furnish their schools with even essential framework.
								 
								 
								 </p>
								 <h6>
							<b>Here is a  detailed list of basic school facilities that provided by us:-</b>
					 
</h6>

							{/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
						</div>						
					</div>	
					{/* <div class="col-lg-5 col-md-12 wow fadeInRight" data-wow-delay="0.3s">
						<div class="about_rotate">
						<img src={fac} alt="about-us" />

						
						</div>						
					</div>										 */}
				</div>


				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss order-lg-2">
						<div class="solar_about_detail">
							<h5 class="solar_center solar_left">Well-Maintained Toilets</h5>
							
							<p>
								
							
Adequate toilet facilities assume great importance in the school premises and they need to be constructed as per the number of students enrolled. In the 21st century, many schools still don’t have an adequate infrastructure that hinders the overall growth of the students. Regular cleaning and washing of the toilets is essential to avoid bacteria and infections.
								 
								 </p>
								

							{/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight order-lg-1" data-wow-delay="0.3s">
						<div class="about_rotate">
						<img src={toilet} alt="about-us" />

						
						</div>						
					</div>										
				</div>




				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss">
						<div class="solar_about_detail">
						<h5 class="solar_center solar_left">Ventilation and Air Quality</h5>
							
							<p>
							Ventilation is imperative in every classroom of the school. Students may develop respiratory conditions and find it hard to stay in the classrooms if the ventilation is inappropriate. On top of that, students having pre-existing respiratory conditions can feel more discomfort, which can affect their learning capabilities. Several studies show that students are not able to focus and feel attentive in classrooms where the air quality is poor. Additionally, bacteria, viruses and many other pathogens can also multiply in the school premises that could have negative effects on the health. Thus, in order to offer quality education to students, it is vital to provide a healthy and safe environment with proper ventilation.
								 </p>
								
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight" data-wow-delay="0.3s">
						<div class="about_rotate">
						
						<img src={ventilation} alt="about-us" />
						</div>						
					</div>										
				</div>



				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss order-lg-2">
						<div class="solar_about_detail">
							<h5 class="solar_center solar_left">Space and Size of Classrooms</h5>
							
							<p>
							Overcrowded classrooms often lead to reduced student engagement and improper learning. Teachers also find it difficult to manage an overcrowded class. Every student is not able to get the required attention. So, every classroom should have moderate number of students.  Additionally, it should be spacious enough to offer adequate and personal space for the students.
								 </p>
								

							{/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight order-lg-1" data-wow-delay="0.3s">
						<div class="about_rotate">
						<img src={classroom} alt="about-us" />

						
						</div>						
					</div>										
				</div>




				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss">
						<div class="solar_about_detail">
						<h5 class="solar_center solar_left">Playgrounds and Sport Facilities</h5>
							
							<p>
							For the improvement of physical and mental health, sports facilities are the most important aspects that parents need to consider before they admit their children to any school.

Many schools lack to provide adequate spacing for playgrounds to students. However, facilities like basketball courts, table tennis tables and badminton and more are essential in today’s era when sports play a major role in students’ lives. Schools must have the infrastructure for at least one outdoor sport and it should be considered by parents before their children are enrolled. Providing cricket nets is also a good option as students are crazy about this sport and it is cost-effective.
								 </p>
								
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight" data-wow-delay="0.3s">
						<div class="about_rotate">
						
						<img src={playground} alt="about-us" />
						</div>						
					</div>										
				</div>




				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss order-lg-2">
						<div class="solar_about_detail">
							<h5 class="solar_center solar_left">Acoustics and Noise</h5>
							
							<p>
							A classroom equipped with furnished desks and table should be a quiet place where the students can have mental peace and they are able to concentrate without any distractions. Noisy classrooms generally have poor effects when it comes to learning.
								 </p>
								

							{/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight order-lg-1" data-wow-delay="0.3s">
						<div class="about_rotate">
						<img src={aquestic} alt="about-us" />

						
						</div>						
					</div>										
				</div>




				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss">
						<div class="solar_about_detail">
						<h5 class="solar_center solar_left">Lighting</h5>
							
							<p>
							Students who get more exposure to natural daylight in the classroom, have more concentration power to grab the learnings. It enhances the morale of the students and teachers that results in quality education. Thus, schools must have proper access to natural light and avoid artificial lights.
								 </p>
								
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight" data-wow-delay="0.3s">
						<div class="about_rotate">
						
						<img src={light} alt="about-us" />
						</div>						
					</div>										
				</div>


				<div class="row  sdsdsds">
					<div class="col-lg-7 col-md-12 setcss order-lg-2">
						<div class="solar_about_detail">
							<h5 class="solar_center solar_left">Laboratory</h5>
							
							<p>
							It is another must-have facility that should be provided in every school. They must have distinct sections for physics, chemistry, biology and computer labs. Computer education is essential for today’s generation, so it should be available to all the students.

							
								 </p>
								<p>
									
With these factors, parents and schools must ensure that they provide the aforementioned list of basic school facilities to students for their well-being along with quality education.
							
								</p>

							{/* <a href="about.html" class="solar_btn my-4">Read More</a> */}
						</div>						
					</div>	
					<div class="col-lg-5 col-md-12 wow fadeInRight order-lg-1" data-wow-delay="0.3s">
						<div class="about_rotate">
						<img src={laboratory} alt="about-us" />

						
						</div>						
					</div>										
				</div>

				</div>





			</div>
		</section>
    </>
  );
}
