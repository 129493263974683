import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function PaymentDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { firstName, lastName, address1, fathername, mothername, gender, dateOfBirth } = formValues;
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Student Details
      </Typography>
      <Typography gutterBottom>{`Name: ${firstName} ${lastName}`}</Typography>
      <Typography gutterBottom>{`Address: ${address1}`}</Typography>
      <Typography gutterBottom>{`F-Name: ${fathername}`}</Typography>
      <Typography gutterBottom>{`M-Name: ${mothername}`}</Typography>
      <Typography gutterBottom>{`Gender: ${gender}`}</Typography>
      <Typography gutterBottom>{`D.O.B: ${mothername}`}</Typography>
    </Grid>
  );
}

export default PaymentDetails;
