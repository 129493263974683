export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required'
    },
    lastName: {
      name: 'lastName',
      label: 'Last name*',
      requiredErrorMsg: 'Last name is required'
    },
    fathername: {
      name: 'fathername',
      label: 'Father name*',
      requiredErrorMsg: 'Father name is required'
    },
    mothername: {
      name: 'mothername',
      label: 'Mother name*',
      requiredErrorMsg: 'Mother name is required'
    },
    gender: {
      name: 'gender',
      label: 'Gender*',
      requiredErrorMsg: 'Gender is required'
    },
    address1: {
      name: 'address1',
      label: 'Address Line 1*',
      requiredErrorMsg: 'Address Line 1 is required'
    },
    address2: {
      name: 'address2',
      label: 'Address Line 2'
    },
    city: {
      name: 'city',
      label: 'City*',
      requiredErrorMsg: 'City is required'
    },
    state: {
      name: 'state',
      label: 'State/Province/Region'
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zipcode*',
      requiredErrorMsg: 'Zipcode is required',
      invalidErrorMsg: 'Zipcode is not valid (e.g. 70000)'
    },
    country: {
      name: 'country',
      label: 'Country*',
      requiredErrorMsg: 'Country is required'
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: 'Use this address for Permamnent Address'
    },
    previousSchoolName: {
      name: 'previousSchoolName',
      label: 'Previous School Name*',
      requiredErrorMsg: 'Previous School Name is required'
    },
    previousSchoolAddress: {
      name: 'previousSchoolAddress',
      label: 'Previous School Address',
      requiredErrorMsg: 'Previous School Address is required',
      // invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    },
    previousSchoolClass: {
      name: 'previousSchoolClass',
      label: 'Previous School Class*',
      requiredErrorMsg: 'Previous School Class is required',
      invalidErrorMsg: 'Previous School Class is not valid'
    },
    newClass: {
      name: 'newClass',
      label: 'New Class*',
      requiredErrorMsg: 'New Class is required',
      
    },
    newClassSection: {
      name: 'newClassSection',
      label: 'New Class Section*',
      requiredErrorMsg: 'New Class Section is required',
      // invalidErrorMsg: 'CVV is invalid (e.g. 357)'
    }
  }
};
